import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import CONFIG from '../../config';
import { makeUrlWithLang } from '../../utils/helpers';

const { asset_host: assetHost, languages } = CONFIG;

const HeadMeta = hostSum => {
  const { currentUrl } = hostSum;

  return (
    <Head>
      <meta
        key="viewport"
        content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        name="viewport"
      />
      <meta name="google" content="notranslate" />
      <meta
        key="format-detection"
        content="telephone=no"
        name="format-detection"
      />
      <meta key="theme-color" content="#ad7a5b" name="theme-color" />

      <link
        href="/static/favicons/favicon-192x192.png"
        rel="apple-touch-icon"
        sizes="192x192"
      />
      <link
        href="/static/favicons/favicon-192x192.png"
        rel="icon"
        sizes="192x192"
        type="image/png"
      />
      <link
        color="#ad7a5b"
        href="/static/favicons/favicon.svg"
        rel="mask-icon"
      />
      <meta
        key="msapplication-config"
        content="/static/browserconfig.xml"
        name="msapplication-config"
      />
      <link href="/static/manifest.webmanifest" rel="manifest" />
      {assetHost && <link href={`//${assetHost}`} rel="dns-prefetch" />}
      <link href={currentUrl} rel="canonical" />
      {languages.map((lang, key) => (
        <link
          key={key}
          hrefLang={lang}
          rel="alternate"
          href={makeUrlWithLang({ ...hostSum, lang })}
        />
      ))}
    </Head>
  );
};

HeadMeta.propTypes = {
  hostSum: PropTypes.object
};

export default HeadMeta;
